import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Ratt from "./Ratt"

export default function Sides() {
  const data = useStaticQuery(graphql`
    {
      allWpSide {
        nodes {
          info {
            price
            description
          }
          title
        }
      }
    }
  `)
  const posts = data.allWpSide.nodes

  return (
    <div className="text-center flex flex-col items-center justify-items-center">
      {posts.map(post => {
        return <Ratt post={post} key={post.id} context="sides" />
      })}
    </div>
  )
}
