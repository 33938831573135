import React from "react"

function MainBurger({ post }) {
  const { title } = post
  const { description, extraInfo, price, vegPrice, doublePrice } = post.info
  return (
    <div className="md:table-row text-sm md:text-base">
      <header className="md:table-cell py-4 text-base">
        <h4 className="food-title  uppercase font-bold">{title}</h4>
        <p className="">{description}</p>
      </header>

      {/* <p className="inline-block md:table-cell text-pink-500 ">
        {price && (
          <span className="inline-block md:hidden text-primary">
            {" "}
            Single:&nbsp;{" "}
          </span>
        )}
        {price && price + "kr"}&nbsp;
      </p>
      <p className="inline-block  md:table-cell text-pink-500 ">
        {doublePrice && (
          <span className="inline-block md:hidden text-primary">
            Double:&nbsp;
          </span>
        )}
        {doublePrice && doublePrice + "kr"}&nbsp;
      </p> */}
      {/* <p className="inline-block  md:table-cell text-pink-500 ">
        {vegPrice && (
          <span className="inline-block md:hidden text-primary">
            Naturli*:&nbsp;
          </span>
        )}
        {vegPrice && vegPrice + "kr"}
      </p> */}
      {/* <div className="text-xs font-italic text-pink-500">{extraInfo}</div> */}
    </div>
  )
}

export default MainBurger
